'use client'

import ExplanationTooltip from '@components/ExplanationTooltip'
import { cx } from '@helpers/cx'

type Props = {
  children: React.ReactNode
  description?: string
  className?: string
  isHidden?: boolean
  name: string
  tooltip?: string
}

export function Input({ children, className, description, isHidden, name, tooltip }: Props) {
  return (
    <div className={cx('min-w-0', className)}>
      {!isHidden && (
        <div className='mb-1 flex w-full flex-col'>
          <span className='text-base font-medium text-gray-900'>
            {name} {tooltip && <ExplanationTooltip position='top' text={tooltip} />}
          </span>
          {description && <span className='mb-1 text-sm text-gray-600'>{description}</span>}
        </div>
      )}
      {children}
    </div>
  )
}
